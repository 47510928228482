import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/emcasa/ui/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Fragment } from 'react';
import Col from '@emcasa/ui-dom/components/Col';
import Row from '@emcasa/ui-dom/components/Row';
import Input from '@emcasa/ui-dom/components/Input';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "input"
    }}>{`Input`}</h1>
    <p>{`Text input.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={0} __code={'<Col mb={2}>\n  <Input label=\"Label\" placeholder=\"Input with label\" />\n</Col>\n<Col mb={2}>\n  <Input placeholder=\"Error\" error=\"Invalid input!\" />\n</Col>\n<Col mb={2}>\n  <Input\n    hideLabelView\n    hideErrorView\n    placeholder=\"Input without label or error views\"\n  />\n</Col>\n<Col mb={2}>\n  <Input\n    fluid={false}\n    label=\"This input is not fluid\"\n    placeholder=\"Placeholder text\"\n  />\n</Col>\n<Col>\n  <Input area label=\"Enter a lot of text\" placeholder=\"A textarea\" />\n</Col>'} __scope={{
      props,
      DefaultLayout,
      Fragment,
      Col,
      Row,
      Input
    }} mdxType="Playground">
  <Col mb={2} mdxType="Col">
    <Input label="Label" placeholder="Input with label" mdxType="Input" />
  </Col>
  <Col mb={2} mdxType="Col">
    <Input placeholder="Error" error="Invalid input!" mdxType="Input" />
  </Col>
  <Col mb={2} mdxType="Col">
    <Input hideLabelView hideErrorView placeholder="Input without label or error views" mdxType="Input" />
  </Col>
  <Col mb={2} mdxType="Col">
    <Input fluid={false} label="This input is not fluid" placeholder="Placeholder text" mdxType="Input" />
  </Col>
  <Col mdxType="Col">
    <Input area label="Enter a lot of text" placeholder="A textarea" mdxType="Input" />
  </Col>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      